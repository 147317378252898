<template>
    <div class="notice-item">
        <div class="title">{{item.title}}</div>
        <div class="desc">{{item.summary}}</div>
        <div class="time">发布时间：{{item.createTime}}</div>
    </div>
</template>

<script>
    export default {
        name: "NoticeItem",
        props:{
            item:{
                type:Object,
                default:function () {

                    return{}
                }
            }
        }
    }
</script>

<style lang="less" scoped>

    .notice-item{

        border-bottom: 1px solid #f2f2f2;
        margin-top: 10px;
        padding-bottom: 10px;
        cursor: pointer;

        .title{
            font-weight: bold;
            font-size: 22px;
            &:hover{
                color: #3B89FF;
            }
        }
        .desc{
            color: #3C3C43;
            font-size: 14px;
            margin-top: 7px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 2;
        }
        .time{
            color: rgba(60,60,67,0.6);
            font-size: 14px;
            margin-top: 8px;
        }
    }
</style>
