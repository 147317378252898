<template>
    <div>
        <div class="user">
            <div class="imgs">
              <img v-if="user.headImg" :src="user.headImg" alt="">
              <img v-else src="../assets/img/user.png" alt="">
            </div>
            <h6>{{user.name}}</h6>
            <span class="num">工号 {{user.username}}</span>
            <el-button type="primary" @click="handelgo('exam')">综合测试</el-button>
            <el-button type="info" @click="handelgo('')">退出系统</el-button>
        </div>
    </div>
</template>

<script>
    export default {
      data() {
        return {
                uesr:''
            }
        },
        created () {
            this.user=JSON.parse(window.sessionStorage.getItem("user"))
        },
        methods: {
          handelgo(i){
            if(i=='exam'){
                 this.$fullscreen.enter()
            }
                this.$router.push('/'+i,)
            },
        },
    }
</script>

<style  scoped>
.user {
  width: 290px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
  border-radius: 6px;
  margin-right: 10px;
}
.user .imgs {
  width: 80px;
  height: 80px;
  opacity: 1;
  margin: 26px auto 10px;
}
.user .imgs img{
  width: 100%;
  height: 100%;
  border-radius: 40px;

}
.user h6 {
  height: 24px;
  opacity: 1;
  font-size: 17px;
  font-family: SF Pro Text, SF Pro Text-Regular;
  font-weight: 400;
  text-align: center;
  color: #000000;
}
.user .num {
  height: 17px;
  opacity: 1;
  font-size: 12px;
  font-family: SF Pro Text, SF Pro Text-Regular;
  font-weight: 400;
  text-align: center;
  color: rgba(60, 60, 67, 0.3);
  margin: 2px 0 20px;
}
.user .el-button {
  height: 36px;
  width: 90%;
  margin: 4px auto;
  line-height: 10px;
  opacity: 1;
  font-size: 15px;
  font-family: SF Pro Text, SF Pro Text-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
}
</style>