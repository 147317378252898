<template>
    <div class="notice">
        <Header/>
        <div class="banner">
            <div class="nav">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>通知公告</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="title">通知公告</div>
            </div>
        </div>
            <div class="content">

                <div class="main">

                    <empty height="400px" v-if="empty"></empty>

                    <div v-for="(item,index) in list" :key="index" @click="handelJump(item.id)">

                        <notice-item  :item="item"></notice-item>

                    </div>

                        <div class="page">
                        <el-pagination
                             @current-change="handleChange"
                            :hide-on-single-page="true"
                            background
                            :page-size="pageSize"
                            layout="total, prev, pager, next"
                            :total="total">
                        </el-pagination>
                        </div>
                </div>

            </div>

            <Footer/>

    </div>
</template>

<script>
import Header from "../components/Header.vue"
import NoticeItem from '@/components/NoticeItem.vue'
import Footer from "../components/Footer.vue"
import User from "../components/User.vue"
import Empty from '@/components/Empty'
import {GetNoticeList} from '../api/list'
    export default {
        components: {
            Header,
            NoticeItem,
            Footer,
            Empty,
            User
        },
        data() {
            return {
                list: [],
                empty:false,
                total:0,
                page:1,
                pageSize:10
            }
        },
        created () {
             this.getNoticeListData()
        },
        methods: {

            //加载通知公告数据
            getNoticeListData(){
                GetNoticeList(this.pageSize,this.page).then(res=>{
                    this.total=res.total;
                    this.list=res.data;
                    this.empty=this.list.length===0

                })
            },
            //分页
            handleChange(val){

                this.page=val;
                this.getNoticeListData()
            },
            handelJump(id) {
                this.$router.push({
                    path:'/notice/noticedetail',
                    query:{id}
                })
            }
        },
    }
</script>

<style  scoped>
@import url(../assets/css/Notice.css);
</style>
